import React, { useState, useRef } from 'react';
import './TestimonialsSection.css';
import { useSwipeable } from 'react-swipeable';
import video1 from '../src/utils/videos/video1.mp4';
import video2 from '../src/utils/videos/video2.mp4';
import video3 from '../src/utils/videos/video3.mp4';
import video4 from '../src/utils/videos/video4.mp4';
import video5 from '../src/utils/videos/video5.mp4';
import playIcon from '../src/utils/images/play-button.png';

const videos = [video1, video2, video3, video4, video5];

const TestimonialsMobile = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRefs = useRef([]);
  const [isPlaying, setIsPlaying] = useState(Array(videos.length).fill(false));

  const togglePlayPause = (index) => {
    const video = videoRefs.current[index];
    if (!video) return;

    if (video.paused) {
      video.muted = false;
      video.play().then(() => {
        togglePlayPauseIcon(index, true);
      }).catch((error) => {
        console.error('Video play failed:', error);
      });
    } else {
      video.pause();
      togglePlayPauseIcon(index, false);
    }
  };

  const togglePlayPauseIcon = (index, playing) => {
    setIsPlaying((prevState) =>
      prevState.map((state, i) => (i === index ? playing : false))
    );

    const overlay = document.querySelectorAll('.play-pause-overlay-mobile')[index];
    if (overlay) {
      overlay.style.display = playing ? 'none' : 'block';
    }
  };

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      pauseAllVideos();
    }
  };

  const handleNextClick = () => {
    if (currentIndex < videos.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      pauseAllVideos();
    }
  };

  const pauseAllVideos = () => {
    videoRefs.current.forEach((video, index) => {
      if (video) {
        video.pause();
        togglePlayPauseIcon(index, false);
      }
    });
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNextClick(),
    onSwipedRight: () => handlePrevClick(),
    preventDefaultTouchmoveEvent: true,
    delta: 10,
    trackMouse: true,
  });

  return (
    <section className="testimonials-section-mobile" {...swipeHandlers}>
      <div className="testimonial-container-mobile">
        <h2 className="testimonials-title-mobile">What Our Clients Say</h2>
        <div className="video-slider-mobile">
          <div
            className="video-grid-mobile"
            style={{
              transform: `translateX(-${currentIndex * 10}%)`,
            }}
          >
            {videos.map((video, index) => (
              <div
                className={`video-card-mobile ${index === currentIndex ? 'active' : ''}`}
                key={index}
              >
                <video
                  className="video-element-mobile"
                  ref={(el) => (videoRefs.current[index] = el)}
                  preload="metadata"
                  muted
                  playsInline
                  controls={false}
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePlayPause(index);
                  }}
                >
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                {!isPlaying[index] && (
                  <div
                    className="play-pause-overlay-mobile"
                    onClick={(e) => {
                      e.stopPropagation();
                      togglePlayPause(index);
                    }}
                  >
                    <img src={playIcon} alt="Play" className="play-icon-mobile" />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsMobile;
