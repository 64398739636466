import React from 'react';
import './ServicesSection.css'; // Import the CSS file
import imigrationImage from "./utils/images/imigrationlaw.png";
import visaImage from "./utils/images/visaassistant.png";
import greencardImage from "./utils/images/greencard.png";
import citizenshipImage from "./utils/images/citizenship.png";



const ServicesSection = () => {
  return (
    <section className="services-section">
      <div className="service-container">
        <h2>Our Services</h2>
        <div className="services-grid">
          <div className="service-card">
            <img src={imigrationImage} alt="Immigration Law" />
            <h3>Immigration Law</h3>
            <p>Expert guidance on all aspects of U.S., Canadian, and Inbound immigration law.</p>
            <a href="/areas-of-expertise" className="view-more">View more</a>
          </div>
          <div className="service-card">
            <img src={visaImage} alt="Visa Assistance" />
            <h3>Visa Assistance</h3>
            <p>Comprehensive support for visa applications and renewals.</p>
            <a href="/areas-of-expertise" className="view-more">View more</a>
          </div>
          <div className="service-card">
            <img src={greencardImage} alt="Green Card Processing" />
            <h3>Green Card Processing</h3>
            <p>Helping you navigate the complexities of green card applications.</p>
            <a href="/areas-of-expertise" className="view-more">View more</a>
          </div>
          <div className="service-card">
            <img src={citizenshipImage} alt="Citizenship Applications" />
            <h3>Citizenship Applications</h3>
            <p>Assistance with your journey to becoming a U.S. or Canadian citizen.</p>
            <a href="/areas-of-expertise" className="view-more">View more</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
