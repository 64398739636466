import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import { FiPhone, FiMail } from 'react-icons/fi';
import './ContactDetails.css'; // Updated the CSS file name

const ContactDetails = () => {
  return (
    <nav className="contact-details">
      <div className="social-icons">
        <a href="https://www.facebook.com/vipul.joshi.5201" target="_blank" rel="noopener noreferrer">
          <FaFacebookF />
        </a>
        <a href="https://www.linkedin.com/in/vipuljoshiassociates/" target="_blank" rel="noopener noreferrer">
          <FaLinkedinIn />
        </a>
        <a href="https://www.instagram.com/vipul.joshi009?igsh=MWZtb2F6eGRiOTg4NQ%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
      </div>
      <div className="contact-details-info">
        <FiPhone className="contact-icon" />
        <span className="contact-text">+91-9920082168</span>
        <FiMail className="contact-icon" />
        <span className="contact-text">joshivipul.law@gmail.com</span>
      </div>
    </nav>
  );
};

export default ContactDetails;
