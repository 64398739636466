import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import './AreasOfExpertise.css'; // Import CSS for styling

const AreasOfExpertise = () => {
  return (
    <section className="expertise-section">

      {/* Helmet for SEO */}
      <Helmet>
        <title>Areas of Expertise | Vipul Joshi & Associates - Immigration Law & Visa Assistance</title>
        <meta
          name="description"
          content="Explore our areas of expertise in U.S. and Canada immigration, visa application assistance, non-immigrant visas, work visas, study permits, and more."
        />
        <meta
          name="keywords"
          content="U.S. immigration, Canada immigration, non-immigrant visas, work visas, visa application assistance, study permits, family-based immigration, asylum, refugee status"
        />
        <meta name="author" content="Vipul Joshi & Associates" />
      </Helmet>
      
      <div className="container">
        <h1 className="expertise-heading">Our Areas of Expertise</h1>

        {/* USA Immigration Section */}
        <div className="expertise-category">
          <h2>USA IMMIGRATION</h2>
          <div className="category-content">
            {/* Non-Immigrant Visa */}
            <h3>Non-Immigrant Visa</h3>
            <p><strong>Tourist Visas (B-2):</strong> For individuals visiting the U.S. for leisure, tourism, or medical treatment.</p>
            <p><strong>Business Visas (B-1):</strong> For short-term business activities, including attending meetings, conferences, etc.</p>

            {/* Student Visa */}
            <h3>Student Visa</h3>
            <p><strong>F-1:</strong> For those pursuing academic studies at accredited U.S. institutions.</p>
            <p><strong>J-1:</strong> For exchange visitors participating in cultural and educational exchange programs.</p>
            <p><strong>M-1:</strong> For those enrolled in vocational or non-academic training programs.</p>

            {/* Work Visa */}
            <h3>Work Visa</h3>
            <p><strong>H-1B:</strong> For specialty occupations requiring a bachelor’s degree or higher.</p>
            <p><strong>L-1:</strong> For intra-company transferees with managerial roles or specialized knowledge.</p>
            <p><strong>O-1:</strong> For individuals demonstrating extraordinary ability in their field.</p>
            <p><strong>E-2:</strong> For investors or employees of businesses in treaty countries.</p>

            {/* Immigrant Visa */}
            <h3>Immigrant Visas</h3>
            <p><strong>Family-Based:</strong> For relatives of U.S. citizens or permanent residents seeking permanent residency.</p>
            <p><strong>Employment-Based:</strong> For individuals seeking permanent residency through employment opportunities.</p>
            <p><strong>Diversity Visa:</strong> For individuals from countries with historically low immigration rates to the U.S.</p>
            <p><strong>Asylum and Refugee Status:</strong> For those seeking protection from persecution due to race, religion, nationality, membership in a particular social group, or political opinion.</p>

            {/* Humanitarian Visas */}
            <h3>Humanitarian Visas</h3>
            <p><strong>U Visa:</strong> For victims of certain crimes who assist law enforcement agencies.</p>
            <p><strong>T Visa:</strong> For victims of severe forms of human trafficking.</p>
            <p><strong>Waiver</strong></p>
            <p><strong>Humanitarian Reinstatement</strong></p>
          </div>
        </div>

        {/* Canada Immigration Section */}
        <div className="expertise-category">
          <h2>Canada Immigration</h2>
          <div className="category-content">
            <p>Vipul Joshi and Associates specializes in assisting Indians with various Canadian immigration applications, 
               including Study Permits, Visitor Visas, Super Visas, Dependent Permanent Residency (PR) applications, 
               and Open Work Permits.</p>

            <h3>Study Permit Application</h3>
            <p>To apply for a Study Permit, a student must demonstrate acceptance from a Designated Learning Institution, proof of 
               sufficient funds, and a genuine intention to study. Applications can be submitted through the Student Direct Stream (SDS) or the General Study Permit route.</p>

            <h3>Visitor Visa</h3>
            <p>A Visitor Visa for Canada requires proof that the applicant is a genuine visitor who will return to their home country after their visit.</p>

            <h3>Super Visa</h3>
            <p>The Super Visa is specifically for the parents of Canadian citizens and allows for extended visits.</p>

            <h3>Dependent PR</h3>
            <p>Dependent PRs are available for spouses of Canadian PR cardholders and citizens. Currently, the program for parents is not operational but may reopen next year.</p>

            <h3>Open Work Permit</h3>
            <p>Open Work Permits are available for spouses of Study Permit holders and PR cardholders.</p>
          </div>
        </div>

        {/* Inbound Immigration Section */}
        <div className="expertise-category">
          <h2>Inbound Immigration</h2>
          <div className="category-content">
            <p>Vipul Joshi & Associates provides assistance with various types of Indian visas, whether for work, tourism, study, business, or compliance with FRRO regulations. The firm also offers support for Overseas Citizen of India (OCI) applications.</p>
          </div>
        </div>

        {/* Visa Application Assistance Section */}
        <div className="expertise-category">
          <h2>Visa Application Assistance</h2>
          <div className="category-content">
            <h3>Comprehensive Assistance</h3>
            <p><strong>Petition Filing:</strong> Expertise in preparing and filing petitions for various visa categories.</p>
            <p><strong>Legal Advice and Counseling:</strong> Guidance on immigration laws, visa options, and strategic planning for complex cases.</p>
            <p><strong>Compliance and Monitoring:</strong> Ongoing support to ensure adherence to immigration laws and visa status compliance.</p>
            <p><strong>Appeals and Litigation:</strong> Representation in appeals and legal proceedings related to visa denials, deportation, or other immigration issues.</p>
            <p><strong>Adjustment of Status:</strong> Assistance with transitioning from a non-immigrant to an immigrant status.</p>
            <p><strong>Naturalization:</strong> Guidance through the process of becoming a U.S. citizen, including interview coaching and exam preparation.</p>
            <p><strong>Application under FOIA:</strong> Assistance with filing applications under the Freedom of Information Act when applications are delayed.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AreasOfExpertise;
