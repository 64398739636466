import React from 'react';
import './TestimonialsReview.css';

const TestimonialsReview = () => {
  const reviews = [
    {
      name: 'Arshita Pathak',
      review: 'I highly recommend Mr. Vipul Joshi and his team for anyone seeking assistance with immigration matters. They provided exceptional support in securing my F1 visa. Despite encountering some technical glitches during the process, Mr. Joshi and his team were incredibly responsive and effective in resolving these issues. Their guidance made the entire process smooth and stress-free. Mr. Joshi was always available to address our queries promptly. I am confident in recommending their services with 100% satisfaction.'
    },
    {
      name: 'Visa Interview Client',
      review: 'Mr. Joshi provided me with potential questions and reviewed my responses in detail. His feedback was incredibly insightful, helping me refine my answers. The mock interview conducted by him was instrumental in boosting my confidence, and many of the questions he predicted were asked in the real interview. Thanks to his guidance, I passed my visa interview with ease.'
    }
  ];

  return (
    <section className="testimonials-review-section">
      <h2>Client Reviews</h2>
      <div className="reviews-container">
        {reviews.map((review, index) => (
          <div className="review-card" key={index}>
            <p className="review-text">"{review.review}"</p>
            <p className="review-author">- {review.name}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TestimonialsReview;
