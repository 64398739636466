import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing head section
import './CaseStudyPage.css'; // Import the CSS file

const caseStudies = [
  {
    title: "I-130 Immigrant Visa Petition Reinstatement",
    description: `An I-130 Immigrant Visa Petition was filed by a US Citizen father for his three daughters. Along with these daughters their husband and children were Dependent Beneficiary. In all these were around 12 applicants. 
    I-130 Petition got approved. However before the Petition got current, the Petitioner expired and therefore as per the process, USCIS revoked the three I-130 Petition. After about 2 years, they came to Vipul Joshi & Associates. 
    In the above 12 people, around 3 kids were aged out. As per the law, children below 21 years of the Principal Applicant are entitled to receive Immigrant Visa. 
    The issue here was two, one was to getting this revoked Petition being reinstated and the other was children who were aged out. The firm filed Humanitarian Reinstatement Application as well as Petition under Child Status Protection Act. Grounds and factors used under the Child Status Protection Act were appreciated and both the applications i.e. Humanitarian Reinstatement Application as well Petitions under Child Status Protection Act were approved. 
    All three families were able to migrate to USA along with the children united with the substitute sponsor i.e. mother of the Primary Applicant
    `,
    outcome: "All three families migrated to the USA along with the children.",
  },
  {
    title: "Retention of Priority Date for US Citizen Sibling Petition",
    description: `An I-130 Immigrant Visa Petition was filed by a US Citizen sister for her brother. In the said Petition, the principal applicant’s wife and a daughter were Dependent Beneficiaries desire to receive Immigrant Visa.  After about 4 years a Request For Evidence (RFE) was sent by USCIS which was not complied within a year and therefore the said Petition was abandoned and revoked by USCIS. The RFE sent was never received by the Petitioner.
    Immediately thereafter Petitioner filed new I-130 Petition for her brother after about 6 years. The principal Applicant then consults after about 5-6 years for the said issue. 
    It is being advised to the client that this case would be very difficult. The approach which was to be taken for the same was to request for retention of priority date of the old Petition to that of the new Petition. To prove the said case, it was argued that the RFE sent in the previous case was never received when the residence address of the Petitioner was same. Application was made to USCIS under Freedom of Information Act and documented related to previous case were gathered. Based on the same points of argument were prepared and along with basic requirement of retention of priority date was justified and proved. 
    USCIS was then convinced that the second Petition is entitled for retention of priority date of the previous petition and therefore the second petition was processed faster. 
    The firm has successfully reopened similar petitions that were previously abandoned and revoked by presenting compelling arguments and supporting evidence to justify their reinstatement.`,
    outcome: "USCIS accepted the argument and processed the second petition faster.",
  },
  {
    title: "Successful Student Visa Application",
    description: `A student visa applicant had faced rejection three times for a student visa and once for a visitor's visa in the past. 
    Additionally, an immigrant visa petition was pending with him as a dependent beneficiary. Despite his impressive academic record, 
    he approached our firm for assistance with his student visa application. Although he was initially rejected once more, 
    we guided him to reapply with a completely new set of responses. Ultimately, his renewed application was successful, 
    and he was granted the student visa.`,
    outcome: "The student was granted the visa.",
  },
  {
    title: "Humanitarian Parole for Special Needs Individual",
    description: `A U.S. citizen son filed an I-130 petition for his parents and sister...An I-130 Petition was filed by a U.S. 
    citizen son for his parents and sister. While the parents, as immediate relatives, were eligible for a quicker immigrant visa, 
    the sister's application would face a longer wait time.
    Given that the sister was a special needs individual with a diagnosed mental health condition, a Humanitarian Parole Application 
    was filed on her behalf. She was granted a two-year parole visa, which allowed her to travel to the U.S. with her parents.
    After the initial two-year period, an extension can be requested. However, if the petition for the sister becomes current during 
    this time, an Adjustment of Status Application can be filed, enabling her to remain in the U.S. permanently.
    `,
    outcome: "The sister received a two-year parole visa with the option for extension.",
  }
];

const CaseStudyPage = () => {
  const [openIndex, setOpenIndex] = useState(0); // Default open case study is 1 (index 0)

  const toggleOpen = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle open/close
  };

  return (
    <section className="case-study-page">

      {/* Helmet for SEO */}
      <Helmet>
        <title>Case Studies | Vipul Joshi & Associates - Immigration Law Success Stories</title>
        <meta
          name="description"
          content="Explore complex immigration case studies successfully handled by Vipul Joshi & Associates, showcasing our expertise in U.S. immigration law."
        />
        <meta name="keywords" content="immigration case studies, immigration law, Vipul Joshi, U.S. immigration success stories, immigration attorney" />
        <meta name="author" content="Vipul Joshi & Associates" />
      </Helmet>
      
      <h1>Our Case Studies</h1>
      <p>Explore some of the complex immigration cases we've successfully resolved.</p>

      {caseStudies.map((study, index) => (
        <div className="case-study" key={index}>
          <div className="case-study-header" onClick={() => toggleOpen(index)}>
            <h3>{`Case Study ${index + 1}`}</h3>
            <span className={`arrow ${openIndex === index ? 'open' : ''}`}>&#9660;</span>
          </div>
          <div className={`case-study-content ${openIndex === index ? 'open' : ''}`}>
            <h3>{study.title}</h3>
            <p>{study.description}</p>
            <p><strong>Outcome: </strong>{study.outcome}</p>
          </div>
        </div>
      ))}
    </section>
  );
};

export default CaseStudyPage;
