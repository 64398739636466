import React, { useState, useRef } from 'react';
import './TestimonialsSection.css';
import video1 from '../src/utils/videos/video1.mp4';
import video2 from '../src/utils/videos/video2.mp4';
import video3 from '../src/utils/videos/video3.mp4';
import video4 from '../src/utils/videos/video4.mp4';
import video5 from '../src/utils/videos/video5.mp4';
import playIcon from '../src/utils/images/play-button.png';

const videos = [video1, video2, video3, video4, video5];

const TestimonialsDesktop = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRefs = useRef([]);
  const [isPlaying, setIsPlaying] = useState(Array(videos.length).fill(false));

  const pauseCurrentVideo = () => {
    const currentVideo = videoRefs.current[currentIndex];
    if (currentVideo) {
      currentVideo.pause();
      currentVideo.currentTime = 0;
      togglePlayPauseIcon(currentIndex, false);
    }
  };

  const togglePlayPause = (index) => {
    const currentVideo = videoRefs.current[currentIndex];
    const newVideo = videoRefs.current[index];

    // If the clicked video is not the current active one, switch to it
    if (index !== currentIndex) {
      // Pause the currently active video
      if (currentVideo) {
        currentVideo.pause();
        togglePlayPauseIcon(currentIndex, false);
      }

      // Set the clicked video as the active one
      setCurrentIndex(index);
      newVideo.muted = false;
      newVideo.play().then(() => {
        togglePlayPauseIcon(index, true);
      }).catch((error) => {
        console.error('Video play failed:', error);
      });
    } else {
      // If the clicked video is already active, toggle play/pause
      if (newVideo.paused) {
        newVideo.muted = false;
        newVideo.play().then(() => {
          togglePlayPauseIcon(index, true);
        }).catch((error) => {
          console.error('Video play failed:', error);
        });
      } else {
        newVideo.pause();
        togglePlayPauseIcon(index, false);
      }
    }
  };

  const togglePlayPauseIcon = (index, playing) => {
    setIsPlaying((prevState) =>
      prevState.map((state, i) => (i === index ? playing : false))
    );

    const overlay = document.querySelectorAll('.play-pause-overlay')[index];
    if (overlay) {
      overlay.style.display = playing ? 'none' : 'block';
    }
  };

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      pauseCurrentVideo();
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < videos.length - 1) {
      pauseCurrentVideo();
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <section className="testimonials-section desktop-view">
      <div className="testimonial-container">
        <h2>What Our Clients Say</h2>
        
        {/* Move arrows outside the video-slider div */}
        <button className="arrow arrow-left" onClick={handlePrevClick}></button>
        
        <div className="video-slider">
          <div
            className="video-grid"
            style={{ transform: `translateX(-${currentIndex * (100 / videos.length)}%)` }}
          >
            {videos.map((video, index) => (
              <div className={`video-card ${index === currentIndex ? 'active' : ''}`} key={index}>
                <video
                  ref={(el) => (videoRefs.current[index] = el)}
                  preload="metadata"
                  muted
                  playsInline
                  controls={false}
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePlayPause(index);
                  }}
                >
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                {!isPlaying[index] && (
                  <div
                    className="play-pause-overlay"
                    onClick={(e) => {
                      e.stopPropagation();
                      togglePlayPause(index);
                    }}
                  >
                    <img src={playIcon} alt="Play" className="play-icon" />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        
        <button className="arrow arrow-right" onClick={handleNextClick}></button>
      </div>
    </section>
  );
};

export default TestimonialsDesktop;
