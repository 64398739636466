import React, { useState } from 'react';
import './ContactUs.css'; // Import the updated CSS file

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    message: '',
  });

  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  // Handle input change
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const backendUrl = 'https://vipuljoshi-backend.onrender.com/submit-form'; // Replace with your backend URL

    try {
      const response = await fetch(backendUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      setIsLoading(false);

      if (data.result === 'success') {
        // Reset form
        setFormData({
          name: '',
          mobile: '',
          email: '',
          message: '',
        });

        // Show thank-you message
        setShowThankYou(true);
        setTimeout(() => setShowThankYou(false), 3000);
      }else {
        setStatus('Failed to send message');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error:', error);
      setStatus('Error occurred while sending your message');
    }
  };

  return (
    <section className="contact-section-redesigned">
      <div className="contact-wrapper">
        <div className="contact-info-redesigned">
          <h2>Contact Us</h2>
          <p>If you have any queries, feel free to reach out to us via the form below or through the following contact details.</p>
          <div className="contact-details-redesigned">
            <p><i className="fas fa-phone-alt"></i> +91-9920082168</p>
            <p><i className="fas fa-envelope"></i> joshivipul.law@gmail.com</p>
            <p><i className="fas fa-map-marker-alt"></i> Office No. 118, Building No. 24/B, Fort, Mumbai, 400001</p>
          </div>
        </div>

        <div className="contact-form-container-redesigned">
          <h3>Send Us a Message</h3>
          <form className="contact-form-redesigned" onSubmit={handleSubmit}>
          <input
              type="text"
              id="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your name"
              required
            />
            <input
              type="tel"
              id="mobile"
              value={formData.mobile}
              onChange={handleChange}
              placeholder="Enter your phone number"
              required
            />
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              required
            />
           <textarea
              id="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              placeholder="Write your message here..."
              required
            ></textarea>
            <button type="submit" disabled={isLoading}>
              {isLoading ? <div className="loader" /> : 'Send Message'}
            </button>
          </form>
        </div>
      </div>

      {showThankYou && (
        <div className="thank-you-overlay-redesigned">
          <div className="thank-you-card-redesigned">
            <p>Thank you for your message. We will get back to you soon!</p>
          </div>
        </div>
      )}
    </section>
  );
};

export default ContactUs;
