import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import './AboutUsPage.css'; // Import the CSS file
import teamPhoto from './utils/images/vipul-photo.webp'; // Replace with your actual image path

const AboutUsPage = () => {
  return (
    <section id="about-us-page">

      {/* Helmet for Meta Information */}
      <Helmet>
        <title>About Us | Vipul Joshi & Associates - Immigration Law Firm</title>
        <meta 
          name="description" 
          content="Learn about Vipul Joshi & Associates, a premier law firm with over 20 years of experience in U.S. immigration law. Discover our expertise and leadership." 
        />
      </Helmet>

      {/* Hero Section */}
      <div className="about-hero">
        <div className="hero-text">
          <h1>About Vipul Joshi & Associates</h1>
          <p>Expert legal guidance with over 20 years of experience in U.S. immigration law, tailored to your unique journey.</p>
        </div>
      </div>

      {/* About Our Firm */}
      <section className="about-section">
        <div className="about-container">
          <h2>About Our Firm</h2>
          <p>
            With over 20 years of experience, Vipul Joshi & Associates has been at the forefront of immigration law. 
            We offer comprehensive solutions to help individuals, families, and businesses navigate the complex world of immigration. 
            Whether you're an individual aiming to reconnect with family, an entrepreneur looking to start a business, or a professional 
            seeking opportunities in the United States, Vipul Joshi & Associates is your reliable partner in achieving these goals.
          </p>
        </div>
      </section>

      {/* Meet Our Leadership Section with Full Content */}
      <section className="leadership-section">
        <div className="leadership-container">
          <div className="leadership-image-container">
            <img src={teamPhoto} alt="Vipul Joshi" className="leadership-image" />
          </div>
          <div className="leadership-details">
            <h3 className="leader-name">Vipul Joshi</h3>
            <p className="leadership-bio">
              Vipul Joshi holds a Bachelor of Law (LLB), Bachelor of Legal Science (BLS), and Bachelor of Commerce from Mumbai University. 
              He has also completed a Certificate Course in Intellectual Property Law from IIPS, with additional distant courses through WIPO, Geneva. 
              He has significant experience from Sudhir Shah & Associates, focusing on U.S. immigration laws, including client consultations, visa applications, L-1 and Waiver Petitions, and research. 
            </p>
            <p className="leadership-bio">
              Vipul Joshi has attended the American Immigration Lawyers Association Annual Conferences in San Antonio (2006) and Orlando (2007), compiled the books "77-QA" and "Oh America, Aah Immigration," and contributed to publications like Mumbai Samachar and Dainik Bhaskar. 
              He has addressed challenges faced by Indian citizens in the U.S. and participated in L-1 Petition conferences in Botswana and Dubai with Mr. Shah to prepare L-1 Petitions for a construction company and a group of hotels, respectively.
            </p>
            <p className="leadership-bio">
              He also managed the drafting of Indian legal documents, including divorce petitions and agreements. Additionally, he gained valuable experience in the basics of the Indian legal system during his tenure at Shetty-Sethna Associates. 
              There, he worked on FERA/FEMA matters, public interest litigation, and intellectual property law, assisting senior partners Advocate L.S. Shetty and Advocate Advait Sethna.
            </p>
            <p className="leadership-bio">
              In the past, he served on the digital panel for the Economic Times, where he addressed readers' questions about immigration laws. Additionally, he wrote articles about U.S. universities for Immigration Times, a publication in vernacular Gujarati based in Anand, Gujarat, India.
            </p>
            <p className="leadership-bio">
              He left Sudhir Shah & Associates on March 31, 2024, and launched his own firm on April 9, 2024, which coincided with his birthday. Since then, he has played a key role in helping a solar energy company establish a branch in the U.S. and secure an L-1 visa. 
              He has also been crucial in assisting a diamond jewelry firm with setting up operations in the U.S. and obtaining an L-1 visa. In addition, he has successfully handled various non-immigrant and immigrant visa applications. 
              He is also part of a New York-based firm, where he offers immigration consultations on an hourly basis.
            </p>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section">
        <div className="cta-container">
          <h2>Get In Touch</h2>
          <p>Ready to discuss your legal needs? Contact us today to learn how we can help you achieve your goals.</p>
          <a href="/contact-us-page" className="aboutus-btn">Contact Us</a>
        </div>
      </section>
    </section>
  );
};

export default AboutUsPage;
