import React, { useState, useEffect } from 'react';
import './HeroSection.css'; // Import the CSS file
import heroImage1 from './utils/images/hero-bg.webp'; // First Image
import heroImage2 from './utils/images/hero-bg2.webp'; // Second Image
import heroImage3 from './utils/images/hero-bg3.webp'; // Third Image

const HeroSection = () => {
  const images = [heroImage1, heroImage2, heroImage3]; // Array of images
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Change the image every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // 5000ms = 5 seconds

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <section className="hero-section">
      <div className="hero-container">
        {/* Image Slideshow */}
        <div className="hero-image">
          <img src={images[currentImageIndex]} alt="Law Firm" />
        </div>
        
        {/* Hero Content */}
        <div className="hero-content">
          <h1>Vipul Joshi & Associates</h1>
          <p>
            Welcome to Vipul Joshi and Associates, Immigration Lawyers, where expert legal guidance meets personalized service.
          </p>
          <a href="/contact-us-page" className="home-page-btn">Contact Now</a>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
