import React, { useState, useEffect } from 'react';
import TestimonialsMobile from './TestimonialSectionMobile';
import TestimonialsDesktop from './TestimonialsSectionDesktop';


const TestimonialsSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {isMobile ? <TestimonialsMobile /> : <TestimonialsDesktop />}
    </>
  );
};

export default TestimonialsSection;
