
import React from 'react';
import './FeaturedPodcast.css';

const FeaturedPodcast = () => {
  return (
    <section id="featured-podcast" className="featured-podcast">
      <div className="container">
        <h2 className="podcast-title">Featured Podcast</h2>
        <p className="podcast-description">
        Our founder, Mr. Vipul Joshi, recently had the honor of being featured on a podcast hosted by the esteemed Rita Haria of Sparkle Entertainment, a prominent figure in the Kutchi community.
        </p>
        <div className="podcast-video">
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/_qTCMZi6AYY?si=n8KEN7OfvlX380jU" 
            frameborder="0" 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
          </iframe>
        </div>
      </div>
    </section>
  );
};

export default FeaturedPodcast;
