import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import './ContactUsPage.css';

const ContactUsPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    message: '',
  });

  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false); // New state to control thank you message

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Show loader
    setIsLoading(true);
    const backendUrl = 'https://vipuljoshi-backend.onrender.com/submit-form';
  
    try {
      const response = await fetch(backendUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      const data = await response.json(); // Parse the JSON response
  
      // Hide loader
      setIsLoading(false);
  
      if (data.result === 'success') {
        // Reset form
        setFormData({
          name: '',
          mobile: '',
          email: '',
          message: '',
        });

        // Show thank you message
        setShowThankYou(true);

        // Hide after 3 seconds
        setTimeout(() => {
          setShowThankYou(false);
        }, 5000);
      } else {
        setStatus('Failed to send message');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error:', error);
      setStatus('Error occurred while sending your message');
    }
  };

  return (
    <section className="contact-us-section">

      {/* Helmet for SEO */}
      <Helmet>
        <title>Contact Us | Vipul Joshi & Associates - Immigration Law Firm</title>
        <meta
          name="description"
          content="Contact Vipul Joshi & Associates for expert legal advice on U.S. immigration law, visa application assistance, and more. We're here to help you achieve your immigration goals."
        />
        <meta name="keywords" content="contact us, immigration lawyer, U.S. immigration, visa assistance, legal advice" />
        <meta name="author" content="Vipul Joshi & Associates" />
      </Helmet>

      <div className="contact-container">
        {showThankYou && (
          <div className="thank-you-overlay">
            <div className="thank-you-card">
              <p>Thank you for reaching out to us.<br/>We will get back to you either on WhatsApp or provided mail.</p>
            </div>
          </div>
        )}

        {/* Left side: Form */}
        <div className="contact-form-container">
          <h2>Contact Us</h2>
          <p>Have a question or want to get in touch? Fill out the form below and we'll respond promptly.</p>
          <form className="contact-form" onSubmit={handleSubmit}>
            <label htmlFor="name">Your Name</label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your name"
              required
            />

            <label htmlFor="mobile">Your Phone Number</label>
            <input
              type="tel"
              id="mobile"
              value={formData.mobile}
              onChange={handleChange}
              placeholder="Enter your phone number"
              required
            />

            <label htmlFor="email">Your Email</label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              required
            />

            <label htmlFor="message">Your Message</label>
            <textarea
              id="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              placeholder="Write your message here..."
              required
            ></textarea>

            <button type="submit" className="btn-submit">
              {isLoading ? <div className="loader" /> : 'Send Message'}
            </button>
          </form>
          {status && <p className="status">{status}</p>}
        </div>

        {/* Right side: Google Map */}
        <div className="contact-map-container">
          <h3>Our Location</h3>
          <div className="google-map">
            <iframe
              title="Our Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7548.043599283864!2d72.8237884935791!3d18.930429200000017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7d156c255bee7%3A0x28648a89b40957af!2sVipul%20Joshi%20%26%20Associates!5e0!3m2!1sen!2sin!4v1725946954235!5m2!1sen!2sin"
              width="100%"
              height="400"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
          <p>
            Office No. 118, 3rd Floor, Building No. 24/B Raja Bahadur Mansion,
            Ambalal Doshi Marg, Behind Gumbo Restaurant, Fort, Mumbai: 400001
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactUsPage;
